.block {
  position: relative;
  width: 100%;
}

.disabled {
  pointer-events: none;

  > * {
    color: #aaa !important;
  }
}

.button {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;

  &_icon {
    padding-right: 12px;
    text-align: right;
    width: 100%;
  }
}

.input {
  border-radius: 24px;
  border: 1px solid rgb(197, 199, 200);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px 11px;
  width: 100%;
}

.inputError {
  border-color: rgb(255, 59, 48) !important;
}

.popup {
  background: white;
  border-radius: 24px;
  border: 1px solid rgb(197, 199, 200);
  position: absolute;
  top: 48px;
  z-index: 10;
}

.picker {
  &__selected {
    background: rgb(182, 245, 115);
    font-weight: bold;
  }

  &__today {
    font-weight: bold;
  }
}
